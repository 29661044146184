import React from 'react'

import { Badge, Paragraph } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Paragraph"
    components={[{ component: Paragraph, restElement: true }]}
  >
    <Section>
      <Paragraph>
        <Badge>16px, 400</Badge>
      </Paragraph>
      <Playground>
        <>
          <Paragraph>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed
            posuere interdum sem. Quisque ligula eros ullamcorper quis, lacinia
            quis facilisis sed sapien. Mauris varius diam vitae arcu. Sed arcu
            lectus auctor vitae, consectetuer et venenatis eget velit. Sed augue
            orci, lacinia eu tincidunt et eleifend nec lacus. Donec ultricies
            nisl ut felis, suspendisse potenti.
          </Paragraph>
          <Paragraph noMargin>
            Lorem ipsum ligula ut hendrerit mollis, ipsum erat vehicula risus,
            eu suscipit sem libero nec erat. Aliquam erat volutpat. Sed congue
            augue vitae neque. Nulla consectetuer porttitor pede. Fusce purus
            morbi tortor magna condimentum vel, placerat id blandit sit amet
            tortor.
          </Paragraph>
        </>
      </Playground>
      <Playground format="html">
        <>
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed
            posuere interdum sem. Quisque ligula eros ullamcorper quis, lacinia
            quis facilisis sed sapien. Mauris varius diam vitae arcu. Sed arcu
            lectus auctor vitae, consectetuer et venenatis eget velit. Sed augue
            orci, lacinia eu tincidunt et eleifend nec lacus. Donec ultricies
            nisl ut felis, suspendisse potenti.
          </p>
          <p className="mb-0">
            Lorem ipsum ligula ut hendrerit mollis, ipsum erat vehicula risus,
            eu suscipit sem libero nec erat. Aliquam erat volutpat. Sed congue
            augue vitae neque. Nulla consectetuer porttitor pede. Fusce purus
            morbi tortor magna condimentum vel, placerat id blandit sit amet
            tortor.
          </p>
        </>
      </Playground>
    </Section>
    <Section title="Tasaus">
      <Playground>
        <>
          <Paragraph align="right">
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Sed
            posuere interdum sem. Quisque ligula eros ullamcorper quis, lacinia
            quis facilisis sed sapien. Mauris varius diam vitae arcu. Sed arcu
            lectus auctor vitae, consectetuer et venenatis eget velit. Sed augue
            orci, lacinia eu tincidunt et eleifend nec lacus. Donec ultricies
            nisl ut felis, suspendisse potenti.
          </Paragraph>
          <Paragraph
            align={{
              md: 'center',
              lg: 'right'
            }}
          >
            Lorem ipsum dolor sit amet.
          </Paragraph>
        </>
      </Playground>

      <Playground format="html">
        <p className="text-right">
          Lorem ipsum ligula ut hendrerit mollis, ipsum erat vehicula risus, eu
          suscipit sem libero nec erat. Aliquam erat volutpat. Sed congue augue
          vitae neque. Nulla consectetuer porttitor pede. Fusce purus morbi
          tortor magna condimentum vel, placerat id blandit sit amet tortor.
        </p>
      </Playground>
    </Section>
  </Content>
)

export default Page
